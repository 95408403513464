<template>
  <v-container class="user-profile-page-content">
    <v-row>
      <!--left pane-->
      <v-col
        v-if="user"
        cols="12"
        md="4"
        lg="3"
        xl="2"
      >
        <v-card
          class="mx-auto"
          width="100%"
        >
          <v-navigation-drawer
            permanent
            width="100%"
            rounded
          >
            <v-list>
              <v-list-item>
                <v-list-item-avatar>
                  <v-avatar
                    color="secondary"
                    size="56"
                  >
                    <span class="white--text">{{ avatarText(user.full_name) }}</span>
                  </v-avatar>
                </v-list-item-avatar>
              </v-list-item>

              <v-list-item>
                <v-list-item-content>
                  <v-list-item-title class="text-h6">
                    {{ user.full_name }}
                  </v-list-item-title>
                  <v-list-item-subtitle>{{ user.email }}</v-list-item-subtitle>
                </v-list-item-content>
              </v-list-item>
            </v-list>
            <v-divider />
            <v-list
              dense
              nav
            >
              <v-list-item-group color="secondary">
                <v-list-item
                  v-for="(item, i) in items"
                  :key="`user-navigation-${i}`"
                  :disabled="item.disabled"
                  :to="{name: item.route}"
                  exact
                  link
                >
                  <v-list-item-icon>
                    <v-icon>{{ item.icon }}</v-icon>
                  </v-list-item-icon>

                  <v-list-item-content>
                    <v-list-item-title>{{ item.text }}</v-list-item-title>
                  </v-list-item-content>
                </v-list-item>
              </v-list-item-group>
            </v-list>
          </v-navigation-drawer>
        </v-card>
      </v-col>

      <!--right pane-->
      <v-col
        cols="12"
        md="8"
        lg="9"
        xl="10"
      >
        <router-view />
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import { mapGetters } from 'vuex';
import { avatarText } from '@/utils/helper';
import routeMap from '@/utils/routeMap';

export default {

  name: 'UserProfile',

  data: () => ({

    items: [
      {
        text: routeMap.user.profile.title,
        icon: routeMap.user.profile.icon,
        disabled: false,
        route: routeMap.user.profile.name
      },
      {
        text: routeMap.user.address.title,
        icon: routeMap.user.address.icon,
        disabled: false,
        route: routeMap.user.address.name
      },
      {
        text: routeMap.user.order.title,
        icon: routeMap.user.order.icon,
        disabled: false,
        route: routeMap.user.order.name
      },
      {
        text: routeMap.user.support.title,
        icon: routeMap.user.support.icon,
        disabled: false,
        route: routeMap.user.support.name
      },
      {
        text: routeMap.user.orderReturn.title,
        icon: routeMap.user.orderReturn.icon,
        disabled: false,
        route: routeMap.user.orderReturn.name
      },
      {
        text: routeMap.user.track.title,
        icon: routeMap.user.shops.icon,
        disabled: true,
        route: routeMap.user.track.name
      },
      {
        text: routeMap.user.shops.title,
        icon: routeMap.user.shops.icon,
        disabled: false,
        route: routeMap.user.shops.name
      },
    ]

  }),

  computed: {

    ...mapGetters({
      user: 'user/data'
    }),

  },
  methods: {

    avatarText(name) {
      if(!name) return '';
      return avatarText(name[0]);
    }

  },
};
</script>

<style scoped>

</style>
